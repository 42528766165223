import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import { useStaticQuery, graphql } from "gatsby"
import styled, { keyframes } from "styled-components"
import gsap from "gsap"
import CookieBar from "./cookie-bar"
import SiteHeader from "./site-header"
import SiteFooter from "./site-footer"
import ContentWrapper from "./content-wrapper"

const getBodyStyles = (color) =>
  typeof window === "undefined" ? { background: color } : `background: ${color}`

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`

const Main = styled.main`
  animation: ${fadeIn} 300ms forwards;
  position: relative;
  z-index: 1;
`

const htmlClassName = typeof window === "undefined" ? "no-js" : "js"

const Layout = ({
  location,
  children,
  title,
  description,
  color,
  ...props
}) => {
  const {
    site: {
      siteMetadata: { name, siteUrl, noindex },
    },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          name
          siteUrl
          noindex
        }
      }
    }
  `)

  const [cookieConsent, setCookieConsent] = useState(true)

  useEffect(() => {
    let timeline = gsap.timeline()

    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            let duration = 0

            if (timeline.isActive()) {
              const timeLeft = timeline.duration() - timeline.time()
              duration = timeLeft < 0.8 ? Math.max(0, timeLeft - 0.1) : 0.7
            }

            const overlap = `-=${duration}`

            timeline.to(
              entry.target,
              {
                opacity: 1,
                transform: "translate(0, 0)",
                ease: "expo.out",
                duration: 0.8,
              },
              overlap
            )
            observer.unobserve(entry.target)
          }
        }
      },
      { rootMargin: "0% 0% -9% 0%" }
    )

    for (const entry of Array.from(document.querySelectorAll(".reveal"))) {
      observer.observe(entry)
    }

    return () => {
      observer.disconnect()

      timeline.kill()

      timeline = null
    }
  }, [])

  /*
  useEffect(() => {
    try {
      const cookieConsented = localStorage.getItem('cookieConsent');

      if (!cookieConsented) {
        setCookieConsent(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);
  */

  function acceptCookies() {
    try {
      localStorage.setItem("cookieConsent", "yes")
      setCookieConsent(true)
    } catch (error) {
      console.error(error)
    }
  }

  const titleTemplate = location.pathname === "/" ? "%s" : `%s – ${name}`

  return (
    <>
      <Helmet
        titleTemplate={titleTemplate}
        defaultTitle={name}
        script={[
          {
            innerHTML:
              "(function(e){e.className=e.className.replace('no-js','js')})(document.documentElement)",
          },
        ]}
      >
        <html className={htmlClassName} />

        <title>{title}</title>

        {noindex && <meta name="robots" content="noindex" />}

        {location && (
          <link rel="canonical" href={`${siteUrl}${location.pathname}`} />
        )}

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon-n.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-n-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-n-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab-n.svg" color="#000000" />
        <link rel="manifest" href="/site-n.webmanifest" />
        <meta name="apple-mobile-web-app-title" content={name} />
        <meta name="application-name" content={name} />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="theme-color" content="#ffffff" />

        {description && <meta name="description" content={description} />}

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        {description && (
          <meta property="og:description" content={description} />
        )}
        <meta property="og:image" content={`${siteUrl}/alliance-sm.png`} />
        {location && (
          <meta property="og:url" content={`${siteUrl}${location.pathname}`} />
        )}
        <meta property="og:site_name" content={name} />

        <meta name="twitter:title" content={title} />
        {description && (
          <meta name="twitter:description" content={description} />
        )}
        <meta name="twitter:image" content={`${siteUrl}/alliance-sm.png`} />

        <body style={getBodyStyles(color)} />
      </Helmet>

      <div {...props}>
        <ContentWrapper>
          <SiteHeader />
          <Main>{children}</Main>
          <SiteFooter />
        </ContentWrapper>
        {!cookieConsent && <CookieBar onAccept={acceptCookies} />}
      </div>
    </>
  )
}

export default Layout
