import React from "react"
import styled from "styled-components"
import { mq, site } from "../styles/variables"

const Wrapper = styled.div`
  max-width: ${site.width}px;
  margin: 0 auto;

  @media ${mq.gte("m")} {
    display: grid;
    grid-template-columns: repeat(14, 1fr);
    grid-column-gap: ${site.gridGutter}px;

    > div {
      width: 100%;
      grid-column: 2 / span 12;
    }
  }
`

const ContenWrappertWrapper = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      <div>{children}</div>
    </Wrapper>
  )
}

export default ContenWrappertWrapper
