import React, { useRef, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import jsonp from "../utils/jsonp"
import SecondaryNavigation from "./secondary-navigation"
import { mq, site } from "../styles/variables"

const Wrapper = styled.footer`
  padding: 50px 0 60px;
  position: relative;

  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: black;
    transform: scale(0, 1);
    transform-origin: 0 50%;
    transition: transform 700ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .no-js &,
  &.is-active::before {
    transform: scale(1, 1);
  }

  @media ${mq.gte("m")} {
    padding: 62px 0 40px;
  }
`

const Columns = styled.div`
  @media (min-width: 1080px) {
    display: flex;
    justify-content: flex-start;
  }

  @media ${mq.gte("l")} {
    margin-bottom: 50px;
  }
`

const Heading = styled.h3`
  margin-bottom: 4px;
`

const Locations = styled.div`
  display: grid;
  grid-column-gap: ${site.gridGutter}px;

  grid-template-columns: repeat(
    ${(props) => Math.ceil(props.$length / 2)},
    1fr
  );

  @media ${mq.gte("m")} {
    grid-template-columns: repeat(${(props) => props.$length}, 1fr);
  }

  @media ${mq.gte("xl")} {
    width: ${(props) => (50 / 3) * props.$length}%;
  }
`

const Location = styled.div`
  font-size: 16px;
  white-space: nowrap;

  @media ${mq.gte("m")} {
    &:not(:first-child) {
      //margin-left: 60px;
    }
  }
`

const Newsletter = styled.div`
  grid-column: span 2 / span 2;
  margin: 80px 0 130px;

  @media (min-width: 770px) {
    margin: 50px 0;
    grid-column: span 4 / span 4;
  }
`

const Form = styled.form`
  margin-top: 23px;
  display: flex;
  gap: 28px;
  align-items: flex-end;
  flex-direction: column;

  @media (min-width: 770px) {
    gap: 20px;
    flex-direction: row;
  }
`

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: 1px solid black;
  background: transparent;
  font-size: 22px;
  padding: 10px 0;
  margin: 0 0 0.12em;
  font-weight: 500;

  @media (min-width: 770px) {
    max-width: 372px;
  }
`

const Submit = styled.button`
  background: black;
  color: white;
  font-size: 22px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 999rem;
  padding: 18px 50px 22px;
  border: 0;
  appearance: none;
  width: 100%;
  transition: opacity 0.2s;

  &:disabled {
    opacity: 0;
    pointer-events: none;
  }

  @media (min-width: 770px) {
    width: auto;
  }
`

const Response = styled.p`
  font-size: 16px;
  margin-top: 34px;
`

const SiteFooter = () => {
  let {
    sanityOfficeLocations: { officeLocations },
  } = useStaticQuery(graphql`
    query SiteFooterQuery {
      sanityOfficeLocations {
        officeLocations {
          _key
          country
          text
        }
      }
    }
  `)

  const [email, setEmail] = useState("")
  const [sending, setSending] = useState(false)
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()

    if (!sending) {
      setSending(true)

      const url = `https://alliance.us7.list-manage.com/subscribe/post-json?u=c9f10f5291bbef11489ff11c8&id=9361709043&f_id=002205e4f0${url}&EMAIL=${email}&b_c9f10f5291bbef11489ff11c8_9361709043=`

      jsonp(url, { param: "c" }, (_, data) => {
        if (data.result === "error") {
          setError(true)
        }

        setSent(true)
      })
    }
  }

  const wrapper = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            wrapper.current.classList.add("is-active")
            observer.unobserve(entry.target)
          }
        }
      },
      { rootMargin: "0px 0px -60px 0px" }
    )

    observer.observe(wrapper.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Wrapper ref={wrapper}>
      <Columns>
        <Locations $length={officeLocations.length}>
          {officeLocations.map(({ _key, country, text }) => (
            <Location key={_key} className="reveal">
              <Heading>{country}</Heading>
              <p
                dangerouslySetInnerHTML={{
                  __html: text.replace(/\n/g, "<br>"),
                }}
              />
            </Location>
          ))}

          <Newsletter className="reveal">
            <Heading>Newsletter</Heading>

            {sent ? (
              <Response
                className="fade-in"
                dangerouslySetInnerHTML={{
                  __html: error
                    ? 'Something went wrong. Please try again later or contact us at <a href="mailto:hello@alliance.vc">hello@alliance.vc</a>.'
                    : "Subscription successful, thank you!",
                }}
              />
            ) : (
              <Form
                action="https://alliance.us7.list-manage.com/subscribe/post?u=c9f10f5291bbef11489ff11c8&amp;id=9361709043&amp;f_id=002205e4f0"
                method="post"
                target="_blank"
                onSubmit={onSubmit}
              >
                <Input
                  value={email}
                  type="email"
                  name="EMAIL"
                  required
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />

                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  <input
                    type="email"
                    name="b_c9f10f5291bbef11489ff11c8_9361709043"
                    tabIndex="-1"
                    value=""
                    required
                    readOnly
                  />
                </div>

                <Submit
                  disabled={!email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)}
                >
                  Subscribe
                </Submit>
              </Form>
            )}
          </Newsletter>
        </Locations>

        <SecondaryNavigation />
      </Columns>
    </Wrapper>
  )
}

export default SiteFooter
