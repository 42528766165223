import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { LinkedIn, Medium, LogoA, X } from "./icons"
import { mq } from "../styles/variables"

const Wrapper = styled.div`
  text-align: right;
  margin: 55px 0 0;

  @media (min-width: 1080px) {
    margin: 0;
    flex-grow: 1;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin: 0;
  }
`

const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 36px 0 0;

  svg {
    margin-left: 35px;
  }

  @media (min-width: 1080px) {
    margin-top: 210px;
  }
`

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Logo = styled.div`
  width: 70px;
  height: 70px;
  margin: 0 0 0 20px;

  @media ${mq.gte("l")} {
    width: 78px;
    height: 78px;
    margin-left: 34px;
  }
`

const SecondaryNavigation = () => {
  return (
    <Wrapper>
      <Navigation className="reveal">
        <List>
          <li>
            <Link to="/about/">About</Link>
          </li>
          <li>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/code-of-conduct/">Code of Conduct</Link>
          </li>
        </List>

        <Logo>
          <LogoA />
        </Logo>
      </Navigation>

      <Social className="reveal">
        <a
          href="https://www.linkedin.com/company/alliance-venture/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedIn />
        </a>
        <a
          href="https://x.com/alliancevc"
          target="_blank"
          rel="noopener noreferrer"
        >
          <X />
        </a>
        <a
          href="https://medium.com/alliance-venture"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Medium />
        </a>
      </Social>
    </Wrapper>
  )
}

export default SecondaryNavigation
