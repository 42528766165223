import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { mq } from "../styles/variables"

const StyledLink = styled(Link)`
  display: inline-block;
  box-shadow: inset 0 -2px rgba(0, 0, 0, 0);
  transition: box-shadow 200ms;

  &:hover,
  &.active {
    box-shadow: inset 0 -2px rgba(0, 0, 0, 1);
  }
`

const Wrapper = styled.nav`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  font-size: 16px;
  line-height: 1.7;
  transition: color 200ms;

  @media ${mq.lt("m")} {
    color: #000 !important;
  }

  > div {
    &:not(:first-child) {
      margin-left: 10px;

      @media (min-width: 360px) {
        margin-left: 22px;
      }

      @media ${mq.gte("m")} {
        margin-left: 45px;
      }

      @media ${mq.gte("l")} {
        margin-left: 66px;
      }
    }
  }

  @media ${mq.gte("m")} {
    font-size: 20px;
    position: relative;
    z-index: 1;
  }
`

const PrimaryNavigation = () => {
  return (
    <Wrapper id="primary-navigation">
      <div>
        <StyledLink to="/portfolio/" activeClassName="active">
          Portfolio
        </StyledLink>
      </div>
      <div>
        <StyledLink to="/team/" activeClassName="active">
          Team
        </StyledLink>
      </div>
      <div>
        <StyledLink to="/contact/" activeClassName="active">
          Contact
        </StyledLink>
      </div>
    </Wrapper>
  )
}

export default PrimaryNavigation
