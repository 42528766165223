import React from "react"
import styled from "styled-components"
import { mq, site } from "../styles/variables"

const Wrapper = styled.div`
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  padding: ${site.gutter.s}px;
  display: flex;
  align-items: center;
  z-index: 1000;

  @media ${mq.gte("m")} {
    font-size: 16px;
    width: 470px;
    left: calc(${site.gutter.s}px + ((100vw - ${site.gutter.s * 2}px) / 14));
    bottom: 40px;
    padding: 30px 34px;
  }

  @media ${mq.gte("l")} {
    left: calc(${site.gutter.l}px + ((100vw - ${site.gutter.l * 2}px) / 14));
  }

  @media (min-width: 1440px) {
    left: calc((100vw - 1164px) / 2);
  }
`

const P = styled.p`
  margin: 0 20px 0 0;
  max-width: 415px;

  @media ${mq.gte("m")} {
    max-width: 214px;
  }
`

const Button = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 2px solid black;
  border-radius: 100em;
  white-space: nowrap;
  margin-left: auto;
  padding: 16px 22px;
  font-size: 16px;
  align-self: flex-end;

  @media ${mq.gte("m")} {
    font-size: 20px;
    padding: 16px 30px;
  }
`

const CookieBar = ({ onAccept }) => {
  return (
    <Wrapper>
      <P>
        On this website we use cookies to enable you as a visitor to adapt the
        appearance of the website.
      </P>
      <Button onClick={onAccept}>Accept</Button>
    </Wrapper>
  )
}

export default CookieBar
