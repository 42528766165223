import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import PrimaryNavigation from "./primary-navigation"
import { Logo as LogoIcon } from "./icons"
import { mq } from "../styles/variables"

const Wrapper = styled.div`
  display: flex;
  margin: 0 0 60px;
  position: relative;
  z-index: 2;
  align-items: flex-end;

  @media ${mq.gte("m")} {
    margin: 0 0 90px;
  }

  @media ${mq.gte("l")} {
    margin: 0 0 120px;
  }
`

const Logo = styled.div`
  width: 94px;
  color: black;
  z-index: 1;
  position: relative;
  top: -5px;

  > svg {
    position: relative;
    z-index: 1;
  }

  @media ${mq.gte("m")} {
    top: -6px;
    width: 142px;
  }
`

const SiteHeader = () => {
  return (
    <Wrapper>
      <Logo>
        <Link to="/" aria-label="Alliance Venture Capital">
          <LogoIcon />
        </Link>
      </Logo>
      <PrimaryNavigation />
    </Wrapper>
  )
}

export default SiteHeader
