import React from "react"

export const Logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 29">
    <path d="M17.37 23.77a4.64 4.64 0 1 1-9.28 0h9.28ZM27.37 1.04h4.26v27.37h-4.26zM35.23 1.04h4.26v27.37h-4.26zM45.21 5.3a2.46 2.46 0 1 0 0-4.91 2.46 2.46 0 0 0 0 4.9ZM43.08 9.92h4.26v18.49h-4.26zM58.6 9.33c2.78 0 4.85 1.3 5.87 3.01V9.92h4.22v18.5h-3.91V25.6h-.12C63.45 27.94 61 29 58.52 29c-4.96 0-8.64-3.99-8.64-9.81 0-5.83 3.76-9.86 8.72-9.86Zm.78 15.92c3.2 0 5.16-2.54 5.16-6.06 0-3.52-1.95-6.14-5.16-6.14-3.2 0-5.23 2.66-5.23 6.14 0 3.48 2.07 6.06 5.23 6.06ZM84.05 28.41V17.4c0-2.82-1.29-4.34-3.48-4.34-2.46 0-4.37 1.95-4.37 4.73V28.4h-4.23V9.91h3.95v2.47H76c1.06-1.8 3.17-3.05 5.83-3.05 3.98 0 6.49 2.7 6.49 7.23v11.85h-4.26ZM108.33 15.63l-4.14.62c-.63-1.99-2.15-3.2-4.42-3.2-3.25 0-5 2.62-5 6.1 0 3.48 1.76 6.1 5 6.1a4.37 4.37 0 0 0 4.38-3.17l3.98.98C106.96 26.89 104 29 99.9 29c-5.71 0-9.39-4.14-9.39-9.85s3.95-9.82 9.15-9.82c4.61 0 7.58 2.15 8.68 6.3ZM119.05 29c-5.71 0-9.66-4.18-9.66-9.77 0-5.6 3.75-9.9 9.19-9.9 5.04 0 9.07 3.52 9.15 9.9v1.05h-14.04c.4 3.1 2.42 5.2 5.4 5.2 1.99 0 3.51-.94 4.33-2.62l3.56 1.06a8.19 8.19 0 0 1-7.93 5.08Zm-5.32-11.61h9.58c-.2-3.29-2.27-4.77-4.66-4.77-2.7 0-4.45 1.72-4.92 4.77Z" />
    <path d="M15.2.89h-4.96L0 28.4h4.73l7.9-22.52h.2l7.9 22.52h4.72L15.21.9ZM127.86 9.92 124.59.89h2.27l2.16 6.4h.05l2.17-6.4h2.14l-3.28 9.03h-2.24ZM137.98.7c2 0 3.5 1.04 4.02 2.86l-2.12.3c-.17-.56-.78-1.27-1.9-1.27-1.49 0-2.45 1.28-2.45 2.81 0 1.54.97 2.8 2.44 2.8.93 0 1.63-.5 1.93-1.35l2 .48c-.48 1.75-1.93 2.78-3.86 2.78-2.66 0-4.58-1.83-4.58-4.7 0-2.86 1.87-4.72 4.51-4.72Z" />
  </svg>
)

export const LogoA = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
    <path d="M0 0h80v80H0z" fill="black" />
    <path
      d="M43.65 18.22h-7.3L21.26 58.8h6.97l11.63-33.2h.28l11.63 33.2h6.97L43.65 18.22Z"
      fill="white"
    />
    <path d="M46.83 51.96a6.84 6.84 0 1 1-13.66 0h13.66Z" fill="white" />
  </svg>
)

export const Arrow = (props) => (
  <svg
    viewBox="0 0 30 25"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    {...props}
  >
    <path
      d="M0 10.85v3.3h23.68l-8.56 8.55L17.4 25 30 12.46 17.4 0l-2.28 2.3 8.6 8.55H0z"
      fillRule="nonzero"
    />
  </svg>
)

export const Checkmark = () => (
  <svg
    viewBox="0 0 14 10"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
  >
    <path d="M5.32 7.14L12.55 0 14 1.43 6.77 8.57 5.32 10 0 4.75l1.45-1.43 3.87 3.82z" />
  </svg>
)

export const Curve404 = (props) => (
  <svg
    viewBox="0 0 593 625"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    {...props}
  >
    <path d="M409.08 532.07c-34.4 36.26-76.61 65.39-123.83 80.97-39.23 12.96-82.07 16.02-122.06 6.06a203.64 203.64 0 01-68.2-31.49 232.18 232.18 0 01-53.58-53.08c-19.23-26.6-32.79-57.28-38.59-89.74-5.1-28.6-3.68-58.82 7.58-85.99 15.3-36.94 48.53-64.94 86.62-78.17 20.95-7.28 43.19-10.57 65.08-11.48 61.39-2.56 122.2 12.4 182.91 24.38 34.2 6.74 68.9 12.65 104 9.95 42.62-3.27 84.98-21.8 112.3-55.32a137.26 137.26 0 0028.6-63.46c5.84-34.43-.62-70.38-15.6-101.76-14.8-31.07-38.62-58.67-69.89-72.36-23.86-10.44-51.2-12.37-76.2-4.96-22.03 6.52-41.8 19.6-58.13 35.85l-.7-.71C393.49 16.78 425.36.12 459.41 0h.57c23.78.1 47.4 8.18 66.74 22.22 35.94 26.07 58.38 69.83 64.58 113.28 3.71 26 1.5 52.98-8.31 77.62a136.77 136.77 0 01-25.8 41.35c-27.55 30.25-68.15 47.24-109.46 50.11-46.8 3.25-93.98-8.2-139.89-17.56-53.13-10.82-106.86-20.98-160.79-15.85-29.94 2.85-59.95 11.06-85.23 27.84-31.94 21.19-54.52 55.4-59.6 94.7-4.1 31.76 2.11 64.21 14.05 93.42 25.32 61.92 79.3 113.03 144.25 130.25 40.33 10.69 83.77 8 123.68-5.04 47.4-15.49 89.76-44.8 123.82-80.6l-13.88 1.6-.11-1 14.88-1.72 1-.11.11 1 1.72 14.9-1 .12-1.66-14.46z" />
  </svg>
)

export const LinkedIn = (props) => (
  <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
  >
    <path d="M29.64 0H2.36A2.33 2.33 0 000 2.3v27.4C0 30.98 1.06 32 2.36 32h27.28c1.3 0 2.36-1.03 2.36-2.3V2.3C32 1.02 30.94 0 29.64 0zM9.7 26.79H4.87V12.34H9.7v14.45zM7.28 10.36h-.03c-1.62 0-2.67-1.1-2.67-2.5 0-1.41 1.08-2.49 2.73-2.49s2.67 1.08 2.7 2.5c0 1.39-1.04 2.5-2.73 2.5zM27.13 26.8H22.3v-7.73c0-1.94-.7-3.27-2.45-3.27-1.34 0-2.13.9-2.48 1.76-.13.3-.16.73-.16 1.17v8.07h-4.84s.07-13.1 0-14.45h4.84v2.05A4.8 4.8 0 0121.56 12c3.19 0 5.57 2.06 5.57 6.5v8.29zm-9.95-12.36l.03-.04v.04h-.03z" />
  </svg>
)

export const Medium = (props) => (
  <svg
    viewBox="0 0 52 30"
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="30"
  >
    <path d="M29.33 15c0 8.28-6.56 15-14.66 15C6.57 30 0 23.28 0 15 0 6.72 6.57 0 14.67 0s14.66 6.72 14.66 15ZM45.42 15c0 7.8-3.28 14.12-7.33 14.12-4.05 0-7.34-6.32-7.34-14.12C30.75 7.2 34.04.88 38.1.88S45.42 7.2 45.42 15M52 15c0 6.98-1.16 12.65-2.58 12.65C48 27.65 46.84 22 46.84 15S48 2.35 49.42 2.35C50.84 2.35 52 8.01 52 15Z" />
  </svg>
)

export const X = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="31"
    viewBox="0 0 30 31"
  >
    <path d="M17.854 12.982 29.022 0h-2.646l-9.698 11.272L8.933 0H0l11.712 17.046L0 30.659h2.647l10.24-11.904 8.18 11.904H30L17.853 12.982h.001Zm-3.625 4.214-1.187-1.698L3.6 1.992h4.065l7.62 10.9 1.187 1.697 9.905 14.168h-4.065l-8.083-11.561Z" />
  </svg>
)
